import type { ProductConfig } from '#types/config/components/product'
import type { RecursivePartial } from '#types/utils'

export default {
  grid: {
    gaps: {
      y: 'md'
    }
  }
} satisfies RecursivePartial<ProductConfig['list']>
