import type { ProductConfig } from '#types/config/components/product'
import type { RecursivePartial } from '#types/utils'

export default {
  addButtonSize: 'tiny',
  badgePosition: 'bottom',
  brandClasses: {
    sectionGallery: 'mb-3 relative',
    swatchCount: 'my-1',
    eyebrow: 'my-1',
    title: 'text-sm my-1 fw-light',
    addButton: 'self-start',
    badge: 'mr-2 h-8 rounded-full shadow-sm',
    badgeLabel: 'c-black'
  },
  maxCarouselItems: 3,
  maxGalleryItems: 3,
  mediaSize: {
    width: 344,
    height: 344
  },
  ratingSize: 'sm'
} satisfies RecursivePartial<ProductConfig['card']>
