<template>
  <div>
    <slot :files />
    <input
      ref="inputRef"
      type="file"
      :accept="accept?.join(',')"
      multiple
      hidden
      @change="onChange"
    >
  </div>
</template>

<script setup lang="ts">
import { FileUploadContextKey } from './context'

const props = withDefaults(defineProps<{
  modelValue?: File[]
  /**
   * The accept file types
   */
  accept?: string[]
  /**
   * The maximum file size in bytes
   */
  maxFileSize?: number
  /**
   * The maximum number of files
   */
  maxFiles?: number
}>(), {
  modelValue: () => [],
  maxFileSize: Number.POSITIVE_INFINITY,
  maxFiles: 1
})

const emit = defineEmits<{
  'update:modelValue': [items: File[]]
  'change': [reason?: 'accept' | 'maxFileSize' | 'maxFiles']
}>()

defineSlots<{
  default: (props: { files: File[] }) => void
}>()

const files = ref<File[]>([])
const inputRef = ref<HTMLInputElement | null>(null)

provide(FileUploadContextKey, {
  inputRef,
  files,
  onDelete
})

function onDelete(file: File) {
  files.value = files.value.filter((f) => f !== file)
  const dt = new DataTransfer()
  files.value.forEach((f) => dt.items.add(f))
  inputRef.value!.files = dt.files
  emit('update:modelValue', files.value)
}

function onChange(event: Event) {
  const target = event.target as HTMLInputElement

  if (!target.files?.length) return

  files.value = [...target.files]

  if (files.value.some((file) => !props.accept?.includes(file.type))) {
    emit('change', 'accept')
    reset()
    return
  }

  if (files.value.length > props.maxFiles) {
    emit('change', 'maxFiles')
    reset()
    return
  }

  if (files.value.some((file) => (file.size > props.maxFileSize))) {
    emit('change', 'maxFileSize')
    reset()
    return
  }

  emit('change')
  emit('update:modelValue', files.value)
}

function reset() {
  files.value = []
  inputRef.value!.value = ''
  emit('update:modelValue', files.value)
}
</script>
