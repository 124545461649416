import type { RecursivePartial } from '#types/utils'
import type { PlpConfig } from '#types/config/pages/plp'

export default {
  filterIconSize: 'md',
  sorts: () => ({
    NORA: [
      'bestMatches',
      'priceLowToHigh',
      'priceHighToLow',
      'rating'
    ],
    EMEA: [
      'bestMatches',
      'priceLowToHigh',
      'priceHighToLow',
      'rating',
      'topSellers',
      'whatsNew'
    ]
  })
} satisfies RecursivePartial<PlpConfig>
