import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    box: 'rounded-full px-2 text-xs',
    disabled: 'c-grey-40 line-through',
    label: 'py-0.5',
    selected: 'bg-black b-transparent c-white',
    unavailable: 'b-transparent c-grey-40 line-through hover:b-black',
    unavailableSelected: 'b-black c-grey-40 line-through'
  },
  productAttributeSelectorSize: {
    fitType: 'sm',
    width: 'sm'
  }
} satisfies RecursivePartial<VfConfig['sizePicker']>
