import type { RecursivePartial } from '#types/utils'
import type { DropdownConfig } from '#types/config/components/vf/dropdown'

export default {
  brandClasses: {
    button: 'rounded-3xl px-4',
    buttonOpen: 'z-2 rounded-b-none b-grey-70 b-b-white bg-white',
    buttonClosed: 'b-grey-90 bg-grey-90',
    popoverContent: 'b-grey-70 b'
  }
} satisfies RecursivePartial<DropdownConfig>
