import type { RecursivePartial } from '#types/utils'
import type { QuickshopConfig } from '#types/config/components/dialog/quickshop'

export default {
  carousel: {
    brandClasses: {
      item: 'snap-center'
    }
  },
  ctaSize: 'xs'
} satisfies RecursivePartial<QuickshopConfig>
