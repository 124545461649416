import type { CMSConfig } from '#types/config/components/cms'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    column: 'space-y-6',
    columns: 'mt-4 px-0',
    link: 'mx-3 px-4 py-3',
    popover: 'relative group',
    shopAll: 'title-4 hover:underlined',
    title: 'title-4',
    visualSelectors: 'mt-4 gap-20',
    visualSelectorsTitle: '',
    visualSelectorsTitleWrapper: 'mt-8'
  },
  underlineOffset: '-0.35rem'
} satisfies RecursivePartial<CMSConfig['megaMenuL1Desktop']>
